// @flow
import React from 'react';
import Layout from '../../components/layout';
import G from '../../components/glossary-link';
import Link from '../../components/link';
import type { FrontMatter } from '../../utils/types';
import { withFrontMatter } from '../../components';
import route from '../../routes';

class AcaCl2SkillsChecklist extends React.Component<{
  frontMatter: FrontMatter,
}> {
  render() {
    return (
      <Layout frontMatter={this.props.frontMatter} toc>
        <p>
          <Link to="https://www.canyoneering.net/wp-content/uploads/2020/05/CL2-Skills-Checklist-4-22-20.pdf">
            Original document
          </Link>{' '}
          from the{' '}
          <G displayTerm={true} id="american-canyoneering-association">
            ACA
          </G>
          's{' '}
          <Link to="https://www.canyoneering.net/resources/">
            resource page
          </Link>
          .
        </p>
        <p>
          OBJECTIVE: To be effective in the safe leadership of a canyoneering
          group, competent in mitigating technical challenges, confident in
          environmental/situational risk assessment, and capable in an
          emergency.
        </p>
        <p>
          NOTE: Skills Checklists are cumulative in nature. Students must know
          all the skills from the '
          <Link to={route.aca_core_skills_checklist}>Core</Link>', '
          <Link to={route.aca_aspirant_skills_checklist}>Aspirant</Link>', and
          the '<Link to={route.aca_cl1_skills_checklist}>CL1</Link>' Checklists
          in addition to the skills listed here.
        </p>

        <h2>Anchors</h2>
        <ul>
          <li>
            Understand correct placement of fixed artificial <G>anchors</G> (
            <G>Bolts</G>) considering rock type and quality, bolt selection
            (i.e. Bolt type (<G>glue-in</G> vs.{' '}
            <G id="expansion-bolt">expansion</G>, diameter, length).
          </li>
          <li>
            Construct, evaluate and rig chock anchors (
            <G id="chockstone">chock stones</G>/
            <G displayTerm={true} id="picket">
              logs
            </G>
            , <G>knot chocks</G>). Explain the pros and cons to using knot chock
            anchors.
          </li>
          <li>
            Safely Rig and use <G>transient anchors</G> including back up and
            test (<G id="sand-bag">Pot Shots</G>, <G>Sand Trap</G>, <G>hooks</G>
            ).
          </li>
        </ul>

        <h2>Core Rope Work</h2>
        <ul>
          <li>
            Demonstrate proper techniques for <G>multi-pitch rappels</G>;
            security, <G>sequencing</G>, <G>rope management</G>.
          </li>
          <li>
            Tension a <G id="guide-line">guide rope</G>: a) at the top with{' '}
            <G>piggyback</G> system using <G>mechanical advantage</G> or counter
            weight, b) at the bottom using an appropriately set{' '}
            <G id="meat-anchor">human anchor</G>.
          </li>
          <li>
            Set up and use a rappel <G>deviation</G> (aka redirect).
          </li>
          <li>
            Set up and use combination <G>rappel</G>-<G>lower</G> to avoid
            multi-pitch rappel or dynamic <G>edge protection</G> (utilizing{' '}
            <G>hands free backup</G>).
          </li>
          <li>
            Set up and use <G>"tail up" retrievable rigging systems</G> (i.e.{' '}
            <G id="fiddlestick">fiddle stick</G>) with backup. Plan for
            contingencies, such as someone stuck on rappel (i.e.{' '}
            <G>top rope belay</G>, <G>rescue rope</G> on standby). Explain pros
            and cons of <G id="tail-up-rigging-system">'tail up' rigging</G> and
            identify situations where is should or should not be employed.
          </li>
          <li>
            Set up and use <G>retrievable rigging systems</G> for{' '}
            <G id="lamar">LMAR</G>; a){' '}
            <G displayTerm={true} id="2-ring-retrievable-rigging">
              retrievable webbing
            </G>
            , b) <G>macrame</G>/<G>CEM</G>. Use secure rigging system for all
            and backup/test for LMAR.
          </li>
          <li>
            Perform controlled rappel <G>free hanging</G> and/or up to 300'+
            adding friction mid-rappel; communication - radios; hanging packs.
          </li>
        </ul>

        <h2>Rescue Rigging and Rescue</h2>
        <ul>
          <li>
            Describe and demonstrate an understanding of how various{' '}
            <G>rigging</G> and <G id="belay">belay systems</G> can facilitate or
            hinder rescue.
          </li>
          <li>
            Demonstrate multiple ways to create a <G>Progress Capture Device</G>{' '}
            (PCD) for <G id="haul-system">hauling systems</G>.
          </li>
          <li>
            Demonstrate basic understanding of mechanical advantage systems
            using <G>pulleys</G> for hauling. Understand and explain how various
            haul systems can increase or decrease the load held by the anchor
            and amount of effort required.
          </li>
          <li>
            Demonstrate <G>cut and lower</G> rescue system utilizing rescue rope
            for someone stuck on a rope that is rigged <G>double-strand</G>. Use
            hands free backup (i.e. <G>friction hitch</G>) when lowering.
          </li>
          <li>
            Convert static <G id="twin-rope-system">twin rope rigging</G> (e.g{' '}
            <G>stone knot</G>) to lower. Use hands free backup (i.e. friction
            hitch) when lowering.
          </li>
          <li>
            Convert tail-up rigging (e.g. fiddlestick) to lower utilizing rescue
            rope. Use hands free backup (i.e. friction hitch) when lowering.
          </li>
          <li>
            Perform a <G>pick-off</G> rescue of a person stuck on rope utilizing
            a second rescue rope, including the use of a <G>self-belay</G>.
          </li>
          <li>
            Perform a pick-off rescue of a person stuck on rope without
            utilizing a second rescue rope, descending the subject’s tensioned
            rope. Include the use of a self-belay.
          </li>
          <li>
            Demonstrate from-the-bottom-rescue, <G>ascending</G> the subject’s
            rope from below,{' '}
            <G displayTerm={true} id="pass-a-knot">
              passing
            </G>{' '}
            the person to a position above them, then lowering them to the
            ground.
          </li>
          <li>
            Demonstrate the use of <G>guide line</G>, track line or tag line
            when hauling or lowering to hold subject away from the wall.
            Identify the risks of using tag lines and strategies for mitigating
            those risks.
          </li>
          <li>
            Demonstrate improvised patient carries that utilize one carrier and
            multiple carriers.
          </li>
        </ul>

        <h2>Canyon Leadership</h2>
        <ul>
          <li>
            Give initial safety briefing which includes: a) Medical/pre-existing
            conditions; b) Weather; c){' '}
            <G displayTerm={true} id="personal-locator-beacon">
              PLB
            </G>{' '}
            usage d) First Aid Kit location; e) <G>Egress</G> plan.
          </li>
          <li>Manage participant fearful of rappelling (from top).</li>
          <li>
            Deal with participant fearful of <G>down climbing</G> /{' '}
            <G displayTerm={true} id="exposure">
              exposed
            </G>{' '}
            <G id="traverse">traversing</G>.
          </li>
          <li>
            Give pertinent/timely in-canyon safety/technical instruction for
            task at hand: a) Downclimbing; b) <G id="rappel">Rappelling</G>; c){' '}
            <G>Belaying</G> d) <G>Partner Capture</G>; e){' '}
            <G>Marginal Anchors</G>; f) Swimming.
          </li>
          <li>
            Wilderness First Aid - perform: a) Immobilize potential back/neck
            injury (do not move); b) Stabilize patient, minor injury & Vertical
            Evacuation.
          </li>
        </ul>

        <h2>Class A/B Canyons — Pothole Escape</h2>
        <ul>
          <li>
            Escaping a <G>pothole</G> using{' '}
            <G displayTerm={true} id="pothole-escape-technique">
              counter-weight techniques
            </G>
            . Deploy counter weights - <G>Pack toss</G> and/or Pot Shots
          </li>
          <li>
            Escaping a pothole using counter-weight techniques. Safely ascend
            multiple ropes simultaneously.
          </li>
          <li>
            Escaping a pothole using counter-weight techniques - Pass the
            pothole lip out.
          </li>
        </ul>

        <h2>Class C Canyons — Core Skills</h2>
        <ul>
          <li>
            Jump into pool and moving water, 10 ft max identify hazards/depth,
            correct body position.
          </li>
          <li>Swim for 6 minutes without floatation.</li>
          <li>Hold breath for 20 seconds underwater.</li>
        </ul>

        <h3>Recommendations After Training</h3>
        <ul>
          Practice technical skills in low-risk conditions, such as on clean and
          low angle "slab" type environments or on vertical terrain with an
          effective <G id="top-rope-belay">Top</G> or Bottom (
          <G id="fireman-belay">Fireman’s</G>) Belay or Self Belay.
        </ul>
        <ul>
          Rescue skills are necessary skills we hope we never need to use. We
          must have the discipline to practice the skills so we can draw upon
          them in those rare moments when they are needed.
        </ul>
      </Layout>
    );
  }
}

export default withFrontMatter('cl2-skills-checklist')(AcaCl2SkillsChecklist);
